.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  height: 100%;
}
#root {
  height: 100%;
}
.rbc-calendar{
  background: white;
}
.bp3-overlay{
  z-index: 500;
}
.rbc-day-slot .rbc-event-label {
  white-space: initial;
}
.rbc-day-slot .rbc-events-container {
  margin-right: 0!important;
}
.rbc-events-container .rbc-event {
   background-color: rgba(79, 42, 215, 0.1);
   color: #1C1F24;
   font-size: 12px;
   line-height: 16px;
 }
.rbc-day-slot .rbc-events-container .rbc-event {
  border: none;
  border-top: 3px solid #4F2AD7;
}
button {
  cursor: pointer;
}
.rc-time-picker > .rc-time-picker-input {
  border: none;
  border-bottom: 2px solid black;
  border-radius: 0;
}
.rbc-allday-cell {
  display: none;
}
.rbc-header {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
}
.MuiIconButton-root {
  height: 48px;
}
.rc-time-picker-panel-combobox .rc-time-picker-panel-select{
  width: 50%;
}
#root .MuiPickersStaticWrapper-staticWrapperRoot {
  background-color: transparent;
  min-width: auto;
}
.MuiPickersStaticWrapper-staticWrapperRoot .MuiPickersBasePicker-pickerView {
  min-width: auto;
}
.MuiPickersStaticWrapper-staticWrapperRoot .MuiPickersCalendarHeader-iconButton {
  background-color: transparent;
}
.MuiCollapse-container {
  cursor: pointer;
}
.MuiInputBase-root .MuiChip-root {
  background-color: #4F2AD7;
  color: white;
  font-weight: 600;
  font-size: 14px;
}
.MuiInputBase-root .MuiChip-deleteIcon {
  color: white;
}
.MuiFormControl-root .MuiAutocomplete-endAdornment {
  top: auto;
}
.MuiFormHelperText-root.Mui-error {
  font-size: 14px;
  line-height: 18px;
}
form .MuiFormHelperText-root{
  color: #f44336!important;
  font-size: 14px;
  line-height: 18px;
}
button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
  cursor: auto;
}
button[disabled]:hover{
  border: #cccccc;
  color: #666666;
  cursor: auto;
}
/*.rbc-event.rbc-selected {*/
/*  background-color: #D7E1F1;*/
/*  color: black;*/
/*  border: none;*/
/*  border-top: 2px solid #065CDE;*/
/*}*/
